const env = process.env.ENV || "development";

interface IConfigs {
  [key: string]: {
    baseURL: string;
    api: {
      v2: string | undefined;
      v3: string | undefined;
    };
    sentry?: {
      dsn: string;
      tracesSampleRate: number;
      debug: boolean;
      environment: string;
      release?: string;
    };
    pyApi: string;
  };
}

// TODO: buckets needs to be changed for development, staging and production
const configs: IConfigs = {
  development: {
    baseURL: process.env.BASE_URL || "https://www.boardinfinity.com",
    api: {
      v2: process.env.API_V2,
      v3: process.env.API_V3
    },
    sentry: {
      dsn: process.env.SENTRY_DSN,
      debug: false,
      tracesSampleRate: 1.0,
      environment: process.env.NODE_ENV
    },
    pyApi: process.env.API_PYTHON
  },
  staging: {
    baseURL: process.env.BASE_URL || "https://www.boardinfinity.com",
    api: {
      v2: process.env.API_V2,
      v3: process.env.API_V3
    },
    sentry: {
      dsn: process.env.SENTRY_DSN,
      debug: false,
      tracesSampleRate: 1.0,
      environment: process.env.ENV
    },
    pyApi: process.env.API_PYTHON
  },
  production: {
    baseURL: process.env.BASE_URL || "https://www.boardinfinity.com",
    api: {
      v2: process.env.API_V2,
      v3: process.env.API_V3
    },
    sentry: {
      dsn: process.env.SENTRY_DSN,
      debug: false,
      tracesSampleRate: 1.0,
      environment: process.env.ENV
    },
    pyApi: process.env.API_PYTHON
  }
};

export default configs[env];
