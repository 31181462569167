import { applyMiddleware, createStore } from "redux";
import { createWrapper } from "next-redux-wrapper";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";

const middleware = [thunk];

export type RootState = ReturnType<typeof rootReducer>;

const makestore = ctx => {
  const initialState = { };

  const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

  return store;
};

const wrapper = createWrapper(makestore);

export default wrapper;
