import * as Sentry from "@sentry/nextjs";
import config from "./config/index";
import packageJson from "./package.json";

if (process.env.NODE_ENV === "production")
  Sentry.init({
    dsn: config.sentry.dsn,
    tracesSampleRate: config.sentry.tracesSampleRate,
    debug: config.sentry.debug,
    environment: config.sentry.environment,
    release: `${packageJson.name}@${packageJson.version}`
  });
