import { CounterAction } from "../actions/counter.action";

export interface ICounter {
  count: number;
}

const initialState: ICounter = {
  count: 0
};

export const counterReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CounterAction.INCREMENT:
      return {
        ...state,
        count: state.count + 1
      };
    case CounterAction.DECREMENT:
      return {
        ...state,
        count: state.count - 1
      };
    default:
      return state;
  }
};
